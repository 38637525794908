/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    padding: 0;
    margin: 0;

    box-sizing: border-box;
    @import "@progress/kendo-font-icons/dist/index.css";

    div {
        font-family: "Mulish", sans-serif;
    }
}

centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

;

// .fa-solid {
//     padding: initial; /* or specific values you want for .fa-solid */
//     margin: initial;  /* or specific values you want for .fa-solid */
//     font-family: initial; /* or a different font-family you want for .fa-solid */
//     box-sizing: initial; /* or the specific box-sizing you want for .fa-solid */
// }

$color-main: #26333F; // Màu chủ đạo
$color-bg: #26333F; // Màu nền
$color-border-input: #B6B6B6; // Màu viền input
$color-normal-text: #000000; // Màu chữ thường
$color-add: #09880E; // Màu thêm mới
$color-cancel: #FF1D1D; // Màu hủy, xóa
$color-send: #069DF1; // Màu gửi đi
$color-bg-side-expanded: #384C5E; // Màu sidebar được expanded
$color-item-grid-selected: #c0cbd4; // Màu item trong grid được chọn
$color-tool-box: #CBCBCB; // Màu của toolbox
$color-readonly: #d4d4d4; // Màu input readonly
$color-coupon: #005C8F; // Màu của coupon



$box-shadow1: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$box-shadow2: rgba(0, 0, 0, 0.15) 0px 5px 15px;
$box-shadow3: rgba(0, 0, 0, 0.35) 0px 5px 15px;
$box-shadow4: rgba(0, 0, 0, 0.25) 0px 5px 10px;
$box-shadow5: rgba(0, 0, 0, 0.24) 0px 3px 8px;
$box-shadow71: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

// Tạo vòng lặp từ 1 đến 12
@for $i from 1 through 12 {

    // Tạo class với tên .col-span-N, trong đó N là số cột
    .col-span-#{$i} {
        grid-column: span $i;
    }
}

// Tạo vòng lặp từ 1 đến 12 (hoặc số cột tối đa bạn muốn)
@for $i from 1 through 12 {

    // Tạo class với tên .grid-cols-N, trong đó N là số cột
    .grid-cols-#{$i} {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(#{$i}, 1fr);
    }
}

@mixin center-vertical() {
    display: flex !important;
    align-items: center !important;
}

@mixin center-horizontal() {
    display: flex !important;
    justify-content: center !important;
}

@mixin center() {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

::-webkit-scrollbar {
    z-index: 2;
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #8d8d8d;
}

kendo-popup {

    .k-list-item:focus,
    .k-list-optionlabel:focus,
    .k-list-item.k-focus,
    .k-focus.k-list-optionlabel {
        box-shadow: none;
    }

    .k-list {
        font-size: 13px;
    }
}

.k-notification-group{
    z-index: 99999999 !important;
} 